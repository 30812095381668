import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../../../utils/media-queries';
import CtaLink from '../../atoms/ctaLink';

const ImageCTA = ({ imageSrc, alt, overline, copy, pageLink, ctaText }) => {
  const [hover, setHover] = useState();

  return (
    <StyledImageCTA
      className="grid"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setTimeout(() => setHover(false), 2000)}
    >
      <Link to={`/${pageLink}`}>
        <p className="overline">{overline}</p>
      </Link>
      <figure>
        <Link to={`/${pageLink}`}>
          <Img fluid={imageSrc} alt={alt} />
        </Link>
      </figure>
      <div className="text">
        <Link to={`/${pageLink}`}>
          <p className="copy spacing-bottom">{copy}</p>
        </Link>
        <CtaLink link={pageLink} ctaText={ctaText} active={hover} />
      </div>
    </StyledImageCTA>
  );
};

const StyledImageCTA = styled.section`
  margin-bottom: calc(var(--v-spacing-L) * 3);

  .overline {
    padding-bottom: 0;
  }

  figure {
    grid-column: 1 / 22;
    max-height: 55vw;
  }

  @media ${media.L} {
    figure {
      grid-column: 1 / 10;
      max-height: 25vw;
    }

    .text {
      grid-column: 10 / 18;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  @media ${media.XL} {
    .text {
      grid-column: 10 / 16;
    }
  }
`;

ImageCTA.propTypes = {
  imageSrc: PropTypes.object,
  alt: PropTypes.string,
  overline: PropTypes.string,
  copy: PropTypes.string,
  ctaText: PropTypes.string,
  pageLink: PropTypes.string,
};

export default ImageCTA;
