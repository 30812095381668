import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../../../utils/media-queries';
import CtaLink from '../../atoms/ctaLink';

const CopyCTA = ({ overline, headline, pageLink, ctaText }) => {
  const [hover, setHover] = useState();

  return (
    <StyledCopyCTA className="grid">
      <div>
        <div>
          <Link
            to={`/${pageLink}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setTimeout(() => setHover(false), 2000)}
          >
            <p className="overline">{overline}</p>
            <p className="bold-small">{headline}</p>
          </Link>
        </div>
        <div>
          <CtaLink link={pageLink} ctaText={ctaText} active={hover} />
        </div>
      </div>
    </StyledCopyCTA>
  );
};

const StyledCopyCTA = styled.section`
  margin-bottom: var(--v-spacing-L);

  div {
    grid-column: 1 / 25;
  }

  @media ${media.L} {
    div {
      grid-column: 1 / 17;
    }
  }

  @media ${media.XL} {
    div {
      grid-column: 1 / 12;
    }
  }
`;

CopyCTA.propTypes = {
  overline: PropTypes.string,
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  pageLink: PropTypes.string,
};

export default CopyCTA;
