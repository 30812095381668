import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../../../utils/media-queries';
import CtaLink from '../../atoms/ctaLink';

const HeroCTA = ({
  imageSrc,
  alt,
  videoSrc,
  overline,
  copy,
  pageLink,
  ctaText,
}) => {
  const [hover, setHover] = useState();

  return (
    <StyledHeroCTA
      className="grid"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setTimeout(() => setHover(false), 2000)}
    >
      <Link to={pageLink}>
        {imageSrc ? (
          <Img fluid={imageSrc} alt={alt} />
        ) : (
          <video src={videoSrc} autoPlay loop muted playsInline />
        )}
      </Link>
      <div className="text spacing-outer">
        <Link to={pageLink}>
          <p className="overline">{overline}</p>
          <h2 className="bold-big">{copy}</h2>
        </Link>
        <CtaLink link={pageLink} ctaText={ctaText} active={hover} />
      </div>
    </StyledHeroCTA>
  );
};

const StyledHeroCTA = styled.section`
  margin-bottom: var(--v-spacing-L);
  position: relative;
  width: 100%;
  height: 80vh;

  .text {
    position: absolute;
    bottom: 0;
    left: 0;
    grid-column: 1 / 25;

    .bold-big,
    .overline {
      color: white;
    }
  }

  @media ${media.L} {
    height: 90vh;

    video {
      height: 90vh;
    }

    .text {
      grid-column: 1 / 18;
    }
  }

  @media ${media.XL} {
    .text {
      grid-column: 1 / 14;
    }
  }
`;

HeroCTA.propTypes = {
  imageSrc: PropTypes.object,
  alt: PropTypes.string,
  videoSrc: PropTypes.string,
  overline: PropTypes.string,
  copy: PropTypes.string,
  ctaText: PropTypes.string,
  pageLink: PropTypes.string,
};

export default HeroCTA;
