import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CtaLink from '../../atoms/ctaLink';

const DonateCTA = ({ ctaText }) => {
  const { ref, inView, entry } = useInView({ threshold: 0.3 });
  const [hover, setHover] = useState();

  return (
    <StyledDonateCTA
      className="grid"
      ref={ref}
      initial={{ opacity: 0, y: -75 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -75 }}
      transition={{ duration: 0.9, ease: 'easeOut' }}
    >
      <Link
        to="/donate"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setTimeout(() => setHover(false), 2000)}
      >
        <motion.p
          className="text-gradient"
          initial={{
            backgroundSize: '100%',
          }}
          animate={{
            backgroundSize: inView ? '10000%' : '100%',
          }}
          transition={{ duration: 4 }}
        >
          Donate.
        </motion.p>
      </Link>
      <CtaLink link="donate" ctaText={ctaText} active={hover} />
    </StyledDonateCTA>
  );
};

const StyledDonateCTA = styled(motion.section)`
  padding: calc(var(--v-spacing-L) * 2) 0;
  margin-bottom: var(--v-spacing-L);
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: var(--font-2);
    font-size: var(--font-size-4);
    text-align: center;
    background-image: linear-gradient(
      178deg,
      rgba(8, 30, 112) 0%,
      rgba(27, 81, 127) 30%,
      rgba(255, 56, 42) 70%,
      rgba(255, 56, 42) 100%
    );
    background-position: top left;
  }

  .link {
    color: var(--red);
    margin: 0 auto;
    a {
      text-align: center;
    }
  }
`;

DonateCTA.propTypes = {
  ctaText: PropTypes.string,
};

export default DonateCTA;
