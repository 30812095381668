import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../../../utils/media-queries';
import CtaLink from '../../atoms/ctaLink';

const ArticleCTA = ({
  imageSrc,
  alt,
  videoSrc,
  overline,
  headline,
  copy,
  pageLink,
  ctaText,
}) => {
  const [hover, setHover] = useState();

  return (
    <StyledArticleCTA
      className="grid"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setTimeout(() => setHover(false), 2000)}
    >
      <figure>
        <Link to={pageLink}>
          {imageSrc ? (
            <Img fluid={imageSrc} alt={alt} />
          ) : (
            <video src={videoSrc} autoPlay loop muted playsInline />
          )}
        </Link>
      </figure>
      <p className="overline-mobile">
        <Link to={pageLink}>{overline}</Link>
      </p>
      <div className="text">
        <div>
          <Link to={pageLink}>
            <p className="overline">{overline}</p>
            <h3 className="bold-small">{headline}</h3>
          </Link>
        </div>
        <div>
          <p className="copy spacing-bottom">
            <Link to={pageLink}>{copy}</Link>
          </p>
          <CtaLink link={pageLink} ctaText={ctaText} active={hover} />
        </div>
      </div>
    </StyledArticleCTA>
  );
};

const StyledArticleCTA = styled.section`
  margin-bottom: var(--v-spacing-L);

  figure {
    grid-column: 1 / 14;
    height: 48vw;
  }

  .overline {
    display: none;
  }

  .overline-mobile {
    grid-column: 14 / 25;
  }

  .text {
    grid-column: 1 / 22;

    .copy {
      margin-right: calc(var(--spacing-L) * 2);
    }
  }

  @media ${media.L} {
    height: 81vh;

    figure {
      grid-column: 1 / 9;
      height: auto;
    }

    .overline {
      display: block;
      grid-column: 14 / 25;
    }

    .overline-mobile {
      display: none;
    }

    .text {
      grid-column: 9 / 21;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .copy {
        margin-right: 0;
      }
    }
  }

  @media ${media.XL} {
    .text {
      grid-column: 9 / 17;
    }
  }
`;

ArticleCTA.propTypes = {
  imageSrc: PropTypes.object,
  alt: PropTypes.string,
  videoSrc: PropTypes.string,
  overline: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  ctaText: PropTypes.string,
  pageLink: PropTypes.string,
};

export default ArticleCTA;
